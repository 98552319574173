<template>
  <div id="convert-info-layout" v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="ci-page-header">
      <el-page-header :title="$t('common.i_back')" :content="giftCard.name" @back="$router.push('/convert')" style="float: left;"></el-page-header>
      <div style="float: right;background-color: white;padding: 2px 6px 3px 6px;border-radius: 20px;">
        <img src="@/assets/common/moneyIcon.png" style="width: 14px;vertical-align: middle;" alt="" />
        <span style="vertical-align: middle;text-decoration: none;color: black;font-size: 14px;padding-right: 2px;">
          {{ $store.state.goldNum }}</span>
      </div>
    </div>

    <div style="padding-left: 8%;padding-right: 8%;">
      <div v-html="giftCard.remarks" style="white-space: pre-line;text-align: left;margin-top: 4%;line-height: 26px;margin-bottom: 2%;"></div>
    </div>

    <div style="margin-left: 8%;margin-right: 8%;">
      <el-form ref="bindGiftCard" :model="bindGiftCard" :rules="formRules" style="text-align: center;">

        <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName">
          <span v-if="form.keyName == 'account'" @click="updateAccount()" style="color: rgba(46, 46, 46, 1);float: right;text-decoration: underline;">{{ $t('convert.i_editAccount') }}</span>
          <el-input v-if="form.keyName == 'account'" type="text" v-model="bindGiftCard.account" :placeholder="form.placeholder"></el-input>
          <el-input v-else-if="form.keyName == 'accountName'" type="text" v-model="bindGiftCard.accountName" :placeholder="form.placeholder"></el-input>
          <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="bindGiftCard.extraOne" :placeholder="form.placeholder"></el-input>
          <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="bindGiftCard.extraTwo" :placeholder="form.placeholder"></el-input>
        </el-form-item>

      </el-form>
    </div>

    <div style="padding-left: 4%;padding-right: 4%;">
      <div style="overflow: hidden;padding-left: 4%;padding-right: 4%;">
        <span style="float: left;line-height: 28px;">{{ $t('convert.i_select') }}</span>
        <span style="float: right;">
          <span style="vertical-align: middle;">{{ $t('convert.i_currency') }} </span>
          <el-select size="mini" style="width: 100px;" v-model="currencyId" @change="getItems()" filterable placeholder="">
            <el-option v-for="currencies in giftCard.currencies" :key="currencies.id" :label="currencies.currencyEn" :value="currencies.currencyId">
            </el-option>
          </el-select>
        </span>
      </div>

      <el-radio-group style="margin-top: 5%;display: block !important;margin-left: 4%;margin-right: 4%;" v-model="convertRadio">
        <div v-for="item in items" :key="item.itemId" style="margin-bottom: 3%;">
          <el-radio-button :label="item.itemId" style="display: block !important;">
            <div style="overflow: hidden;margin-top: 2px;">
              <span style="float: left;">
                <img style="width: 16px;vertical-align: middle;" src="@/assets/common/moneyIcon.png" alt="" />
                <span style="font-size: 24px;vertical-align: middle;"> {{ item.gold }}</span>
              </span>
              <img width="20" src="@/assets/images/convert001.png" alt="" />
              <span style="float: right;font-size: 24px;">{{ item.goldValue }}</span>
            </div>
          </el-radio-button>
        </div>
      </el-radio-group>

      <div style="padding-bottom: 5%;">
        <el-button type="primary" @click="toConvert('bindGiftCard')" style="background-color: rgba(252, 146, 72, 1);border-radius: 30px;width: 50%;margin-top: 5%;">
          {{ $t('convert.i_redeemNow') }}</el-button>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_bindAccount')" :visible.sync="bindDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div v-if="bindActive == 1">
        <el-form ref="bindForm" :model="bindForm" :rules="formRules" style="text-align: center;">

          <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName">
            <el-input v-if="form.keyName == 'account'" type="text" v-model="bindForm.account" :placeholder="form.placeholder"></el-input>
            <el-input v-else-if="form.keyName == 'accountName'" type="text" v-model="bindForm.accountName" :placeholder="form.placeholder"></el-input>
            <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="bindForm.extraOne" :placeholder="form.placeholder"></el-input>
            <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="bindForm.extraTwo" :placeholder="form.placeholder"></el-input>
          </el-form-item>

          <div style="text-align: left;color: rgba(161, 134, 107, 1);font-size: 11px;padding-bottom: 8%;">*{{ $t('convert.i_bindN') }}</div>
        </el-form>
        <div style="text-align: center;">
          <el-button type="primary" @click="onBindCheck('bindForm')" style="background-color: rgba(252, 146, 72, 1);border-radius: 30px;width: 60%;">{{ $t('convert.i_bindNow') }}
          </el-button>
          <div style="margin-top: 4%;">
            <span @click="$router.go(-1)" style="color: rgba(144, 144, 144, 1);">{{ $t('common.i_cancel') }}</span>
          </div>
        </div>
      </div>

      <div v-if="bindActive == 2">
        <p>{{ $t('convert.i_following') }} {{ giftCard.name }} {{ $t('convert.i_bindT') }}</p>

        <div v-for="(form, index) in giftCard.forms" :key="index">
          <label>{{ form.label }}：</label>
          <p v-if="form.keyName == 'account'" style="color: rgba(60, 88, 154, 1);">{{ bindForm.account }}</p>
          <p v-if="form.keyName == 'accountName'" style="color: rgba(60, 88, 154, 1);">{{ bindForm.accountName }}</p>
          <p v-if="form.keyName == 'extraOne'" style="color: rgba(60, 88, 154, 1);">{{ bindForm.extraOne }}</p>
          <p v-if="form.keyName == 'extraTwo'" style="color: rgba(60, 88, 154, 1);">{{ bindForm.extraTwo }}</p>
        </div>

        <div style="text-align: center;">
          <el-button type="primary" @click="toBindGiftCard()" style="background-color: rgba(252, 146, 72, 1);border-radius: 30px;width: 60%;">{{ $t('convert.i_bind') }}
          </el-button>
          <div style="margin-top: 4%;">
            <span @click="$router.go(-1)" style="color: rgba(144, 144, 144, 1);">{{ $t('common.i_cancel') }}</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_modifyReceivingAccount')" :visible.sync="updateDialog" width="85%" center>
      <div v-if="updateActive == 1">
        <el-form ref="updateForm" :model="updateForm" :rules="formRules" style="text-align: center;">

          <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName">
            <el-input v-if="form.keyName == 'account'" type="text" v-model="updateForm.account" :placeholder="form.placeholder"></el-input>
            <el-input v-else-if="form.keyName == 'accountName'" type="text" v-model="updateForm.accountName" :placeholder="form.placeholder"></el-input>
            <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="updateForm.extraOne" :placeholder="form.placeholder"></el-input>
            <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="updateForm.extraTwo" :placeholder="form.placeholder"></el-input>
          </el-form-item>

          <div style="text-align: left;color: rgba(161, 134, 107, 1);font-size: 11px;padding-bottom: 8%;">*{{ $t('convert.i_bindN') }}</div>
        </el-form>
        <div style="text-align: center;">
          <el-button type="primary" @click="onUpdateCheck('updateForm')" style="background-color: rgba(252, 146, 72, 1);border-radius: 30px;width: 60%;">{{ $t('convert.i_changeNow') }}
          </el-button>
          <div style="margin-top: 4%;">
            <span style="color: rgba(144, 144, 144, 1);" @click="updateDialog = false">{{ $t('common.i_cancel') }}</span>
          </div>
        </div>
      </div>

      <div v-if="updateActive == 2">
        <p>{{ $t('convert.i_changeT') }}</p>

        <div v-for="(form, index) in giftCard.forms" :key="index">
          <label>{{ form.label }}：</label>
          <p v-if="form.keyName == 'account'" style="color: rgba(60, 88, 154, 1);">{{ updateForm.account }}</p>
          <p v-if="form.keyName == 'accountName'" style="color: rgba(60, 88, 154, 1);">{{ updateForm.accountName }}</p>
          <p v-if="form.keyName == 'extraOne'" style="color: rgba(60, 88, 154, 1);">{{ updateForm.extraOne }}</p>
          <p v-if="form.keyName == 'extraTwo'" style="color: rgba(60, 88, 154, 1);">{{ updateForm.extraTwo }}</p>
        </div>

        <div style="text-align: center;">
          <el-button type="primary" @click="toUpdateGiftCard()" style="background-color: rgba(252, 146, 72, 1);border-radius: 30px;width: 60%;">{{ $t('convert.i_confirm') }}
          </el-button>
          <div style="margin-top: 4%;">
            <span style="color: rgba(144, 144, 144, 1);" @click="updateDialog = false; active = 1;">{{ $t('common.i_cancel') }}</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_convert') + ' - ' + convertTitle" :visible.sync="convertMsgDialog" width="85%" center :closeOnClickModal="convertCloseOnClickModal" :closeOnPressEscape="convertCloseOnPressEscape" :showClose="convertShowClose">
      <div style="text-align: center;">
        <div>
          <span style="font-size: 16px;color: #000;">{{ convertMsg }}</span>
        </div>
        <div style="margin-top: 10%;">
          <el-button type="primary" @click="convertOk()" style="width: 60%;background-color: rgba(252, 146, 72, 1);border-radius: 30px;">{{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_convert') + ' - ' + $t('convert.i_tips')" :visible.sync="convertDialog" width="85%" center>
      <div style="text-align: center;margin-bottom: 6%;">
        <img src="@/assets/images/convert002.png" style="width: 70px;margin-bottom: 6%;" alt="" />
        <div>
          <span style="font-size: 16px;color: #000;">{{ $t('convert.i_convertMsg') }}</span>
        </div>
      </div>
      <div style="text-align: center;">
        <el-button type="primary" @click="$router.push('/convertLog')" style="background-color: rgba(252, 146, 72, 1);border-radius: 30px;width: 60%;">{{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="convertBindDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;">
        <div>
          <span style="font-size: 16px;color: #000;">{{ $t('convert.i_activateMsg') }}</span>
        </div>
      </div>
      <div style="text-align: center;margin-top: 10%;">
        <el-button @click="$router.push('/moneySteps')" style="width: 60%;background-color: rgba(252, 146, 72, 1);color: white;border-radius: 30px;">
          {{ $t('convert.i_ok') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    const checkWhetherSpace = (rule, value, callback) => {
      this.giftCard.forms.forEach((form) => {
        if (form.keyName == rule.field) {
          if (form.regexps != null && !new RegExp(form.regexps).test(value)) {
            callback(new Error(form.regexpsTips));
          } else {
            callback();
          }
        }
      });
    };
    return {
      isLoading: false,
      convertTitle: "",
      convertMsg: "",
      convertShowClose: true,
      convertCloseOnPressEscape: true,
      convertCloseOnClickModal: true,
      convertBindDialog: false,
      convertStatus: 3,
      bindDialog: false,
      updateDialog: false,
      convertDialog: false,
      convertMsgDialog: false,
      bindActive: 1,
      updateActive: 1,
      currencyId: "",
      convertRadio: "",
      giftCard: {},
      items: [],
      bindGiftCard: {
        cardId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      convertInfo: {
        itemId: "",
        payee: "",
        accountId: "",
        qrCode: "",
        extraOne: "",
        extraTwo: ""
      },
      bindForm: {
        cardId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      updateForm: {
        cardId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      formRules: {
        account: [
          {
            required: true,
            message: this.$t("convert.i_accountN"),
            trigger: ["blur", "change"]
          },
          {
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        accountName: [
          {
            required: true,
            message: this.$t("convert.i_nameN"),
            trigger: ["blur", "change"]
          },
          {
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        extraOne: [
          {
            required: true,
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        extraTwo: [
          {
            required: true,
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ]
      },
      regexps: "",
      regexpsTips: ""
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onBindCheck(formName) {
      this.$refs[formName].validateField(["account"], (validErr) => {
        if (!validErr) {
          if (this.giftCard.forms.length >= 2) {
            this.$refs[formName].validateField(["accountName"], (validErr) => {
              if (!validErr) {
                if (this.giftCard.forms.length >= 3) {
                  this.$refs[formName].validateField(
                    ["extraOne"],
                    (validErr) => {
                      if (!validErr) {
                        if (this.giftCard.forms.length >= 3) {
                          this.$refs[formName].validateField(
                            ["extraTwo"],
                            (validErr) => {
                              if (!validErr) {
                                this.bindActive = 2;
                              }
                            }
                          );
                        } else {
                          if (!validErr) {
                            this.bindActive = 2;
                          }
                        }
                      }
                    }
                  );
                } else {
                  if (!validErr) {
                    this.bindActive = 2;
                  }
                }
              }
            });
          } else {
            if (!validErr) {
              this.bindActive = 2;
            }
          }
        }
      });
    },
    onUpdateCheck(formName) {
      this.$refs[formName].validateField(["account"], (validErr) => {
        if (!validErr) {
          if (this.giftCard.forms.length >= 2) {
            this.$refs[formName].validateField(["accountName"], (validErr) => {
              if (!validErr) {
                if (this.giftCard.forms.length >= 3) {
                  this.$refs[formName].validateField(
                    ["extraOne"],
                    (validErr) => {
                      if (!validErr) {
                        if (this.giftCard.forms.length >= 3) {
                          this.$refs[formName].validateField(
                            ["extraTwo"],
                            (validErr) => {
                              if (!validErr) {
                                this.updateActive = 2;
                              }
                            }
                          );
                        } else {
                          if (!validErr) {
                            this.updateActive = 2;
                          }
                        }
                      }
                    }
                  );
                } else {
                  if (!validErr) {
                    this.updateActive = 2;
                  }
                }
              }
            });
          } else {
            if (!validErr) {
              this.updateActive = 2;
            }
          }
        }
      });
    },
    convertOk() {
      if (this.convertStatus == 1) {
        this.$router.go(-1);
      } else if (this.convertStatus == 3) {
        this.convertMsgDialog = false;
      }
    },
    updateAccount() {
      this.updateActive = 1;
      this.updateDialog = true;
    },
    getItems() {
      this.giftCard.currencies.forEach((currency) => {
        if (currency.currencyId == this.currencyId) {
          this.items = currency.items;
        }
      });
      this.convertRadio = this.items[0].itemId;
    },
    queryBindCard() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/web/query/bind?cardId=" + this.giftCard.cardId,
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        console.log(result.data);
        if (result.data.code == 1) {
          if (result.data.data.isActivation == false) {
            this.convertBindDialog = true;
          } else {
            if (
              result.data.data.updateLog == null ||
              result.data.data.accountBind == null
            ) {
              if (result.data.data.accountBind == null) {
                this.bindDialog = true;
              } else {
                this.bindGiftCard.account =
                  result.data.data.accountBind.bindAccount;
                this.bindGiftCard.accountName =
                  result.data.data.accountBind.accountName;
                this.bindGiftCard.extraOne =
                  result.data.data.accountBind.extraOne;
                this.bindGiftCard.extraTwo =
                  result.data.data.accountBind.extraTwo;
                this.updateForm.account = this.bindGiftCard.account;
                this.updateForm.accountName = this.bindGiftCard.accountName;
                this.updateForm.extraOne = this.bindGiftCard.extraOne;
                this.updateForm.extraTwo = this.bindGiftCard.extraTwo;
              }
            } else {
              this.bindGiftCard.account =
                result.data.data.accountBind.bindAccount;
              this.bindGiftCard.accountName =
                result.data.data.accountBind.accountName;
              this.bindGiftCard.extraOne =
                result.data.data.accountBind.extraOne;
              this.bindGiftCard.extraTwo =
                result.data.data.accountBind.extraTwo;
              this.updateForm.account = this.bindGiftCard.account;
              this.updateForm.accountName = this.bindGiftCard.accountName;
              this.updateForm.extraOne = this.bindGiftCard.extraOne;
              this.updateForm.extraTwo = this.bindGiftCard.extraTwo;
              this.convertStatus = result.data.data.updateLog.status;
              if (result.data.data.updateLog.status == 1) {
                this.convertShowClose = false;
                this.convertCloseOnPressEscape = false;
                this.convertCloseOnClickModal = false;
                this.convertTitle = this.$t("convert.i_info_ing");
                this.convertMsg = result.data.data.updateLog.msg;
                this.convertMsgDialog = true;
              } else if (result.data.data.updateLog.status == 3) {
                this.convertTitle = this.$t("convert.i_infoN");
                this.convertMsg = result.data.data.updateLog.msg;
                this.convertMsgDialog = true;
              }
            }
          }
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    toBindGiftCard() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/web/bind",
        method: "post",
        params: this.bindForm
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.queryBindCard();
          this.bindDialog = false;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
          this.bindActive = 1;
        }
      });
    },
    toUpdateGiftCard() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/web/account/update",
        method: "post",
        params: this.updateForm
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.$router.push("/convert");
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
      this.updateDialog = false;
    },
    onConvert() {
      this.convertInfo.itemId = this.convertRadio;
      this.convertInfo.payee = this.bindGiftCard.accountName;
      this.convertInfo.accountId = this.bindGiftCard.account;
      this.convertInfo.extraOne = this.bindGiftCard.extraOne;
      this.convertInfo.extraTwo = this.bindGiftCard.extraTwo;
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/web/exchange",
        method: "post",
        params: this.convertInfo
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.convertDialog = true;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    toConvert(formName) {
      this.$refs[formName].validateField(["account"], (validErr) => {
        if (!validErr) {
          if (this.giftCard.forms.length >= 2) {
            this.$refs[formName].validateField(["accountName"], (validErr) => {
              if (!validErr) {
                if (this.giftCard.forms.length >= 3) {
                  this.$refs[formName].validateField(
                    ["extraOne"],
                    (validErr) => {
                      if (!validErr) {
                        if (this.giftCard.forms.length >= 3) {
                          this.$refs[formName].validateField(
                            ["extraTwo"],
                            (validErr) => {
                              if (!validErr) {
                                this.onConvert();
                              }
                            }
                          );
                        } else {
                          if (!validErr) {
                            this.onConvert();
                          }
                        }
                      }
                    }
                  );
                } else {
                  if (!validErr) {
                    this.onConvert();
                  }
                }
              }
            });
          } else {
            if (!validErr) {
              this.onConvert();
            }
          }
        }
      });
    }
  },
  created() {
    const giftCards = JSON.parse(sessionStorage.getItem("giftCards"));
    this.$store.state.goldNum = JSON.parse(sessionStorage.getItem("goldNum"));
    giftCards.forEach((giftCard) => {
      if (giftCard.cardId == this.id) {
        this.giftCard = giftCard;
      }
    });
    this.currencyId = this.giftCard.currencies[0].currencyId;
    this.items = this.giftCard.currencies[0].items;
    this.convertRadio = this.giftCard.currencies[0].items[0].itemId;
    this.bindForm.cardId = this.giftCard.cardId;
    this.updateForm.cardId = this.giftCard.cardId;
    this.regexps = this.giftCard.forms[0].regexps;
    this.regexpsTips = this.giftCard.forms[0].regexpsTips;
    this.queryBindCard();
  }
};
</script>

<style>
#convert-info-layout {
  width: 100%;
  height: 100%;
}

#convert-info-layout .el-radio-button__inner {
  background-color: rgba(193, 180, 255, 1) !important;
  color: black !important;
}

#convert-info-layout .is-active .el-radio-button__inner {
  background-color: rgba(42, 197, 255, 1) !important;
  color: white !important;
}

#convert-info-layout .ci-page-header {
  padding-top: 4%;
  padding-left: 4%;
  padding-right: 4%;
  overflow: hidden;
}

#convert-info-layout .el-dialog {
  border-radius: 20px !important;
}

#convert-info-layout .el-dialog__header {
  border-radius: 20px 20px 0 0 !important;
}

#convert-info-layout .el-dialog__body {
  border-radius: 0 0 20px 20px !important;
}

#convert-info-layout .el-radio-button__inner {
  display: block !important;
}

.el-loading-mask {
  height: 100% !important;
  padding-bottom: 20%;
}

#convert-info-layout .el-form-item__label {
  color: black !important;
}

#convert-info-layout .el-form-item__error {
  color: rgb(238, 44, 44) !important;
}

#convert-info-layout
  .el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
#convert-info-layout
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  color: rgba(252, 146, 72, 1) !important;
}
</style>
