const en = {
  common: {
    i_loading: "Loading...",
    i_back: "Back",
    i_timedOut: "Request timed out, please check network",
    i_startEarning: "Start earning",
    i_copy: "Copied",
    i_cancel: "Cancel"
  },
  vCode: {
    i_sliderText: " Drag to complete the puzzle",
    i_failText: "Failed, please try again",
    i_successText: "Successfully verified"
  },
  register: {
    i_register: "Register",
    i_enterYourEmail: "Enter your Email",
    i_getVerifyCode: "Get verify code",
    i_enterVerifyCode: "Enter verify code",
    i_enterPass: "Enter password",
    i_checkYourPass: "Confirm your password",
    i_inviteCodeT: "Invitation code (optional)",
    i_enterInviteCode: "Enter invitation code",
    i_autoLogin: "Auto login",
    i_goRegister: "Sign up now",
    i_goLogin: "Go Login",
    i_registeredSuccess: "Registered successfully",
    i_startEarning: "Go login and start earning!",
    i_enter6VerifyCode: "Please enter a 6-digit verification code",
    i_specialSymbols: "Special symbols can only be ~!@#$%^&*",
    i_pass6: "Password cannot be less than 6 digits",
    i_pass30: "Password cannot be greater than 30 characters",
    i_checkTwoPass: "The passwords entered twice do not match",
    i_emailAddressEmpty: "Email address cannot be empty",
    i_checkEmail: "Please input the correct email address",
    i_verifyCodeEmpty: "Verification code cannot be empty",
    i_passEmpty: "Password can not be blank",
    i_checkPassEmpty: "Confirm password can not be blank",
    i_inProgress: "In progress",
    i_reRequest: "re-request",
    i_verifyCodeMsg:
      "The verification code has been sent to your email, please check it!",
    i_pleaseUseNewPlatform: "Please use our new platform"
  },
  login: {
    i_playGames: "Play games and earn cash",
    i_login: "Login",
    i_enterYourEmail: "Enter your email",
    i_enterYourPass: "Enter your password",
    i_forgotPass: "Forgot password?",
    i_goLogin: "Go Login",
    i_goRegister: "Register"
  },
  forgotPassword: {
    i_forgotPass: "Forgot password",
    i_verify: "Verify",
    i_modify: "Modify",
    i_finish: "Finish",
    i_next: "Next",
    i_enterYourNewPass: "Enter your new password",
    i_updatePass: "Change the password",
    i_successUpdatePass: "Successfully change password",
    i_goLoginMsg: "Go to login and start earning!"
  },
  index: {
    i_coinsLog: "Coins record",
    i_totalCoins: "Total coins",
    i_convert: "Withdraw",
    i_convertMsg: "Coins for cash",
    i_inviteFriends: "Invite friends",
    i_inviteFriendsMsg: "Get more coins",
    i_inviteCode: "Code",
    i_accountC: "Account",
    i_account: "Account",
    i_leaderboard: "Ranking",
    i_contact: "Contact",
    i_language: "en"
  },
  moenySteps: {
    i_earningSteps: "Earning steps",
    i_moenyStepsMsg:
      "The number of coins in the game is the number of coins of your account",
    i_download: "Download app",
    i_activate: "Activate Premium Features",
    i_status: "Status",
    i_activateY: "Activated",
    i_activateN: "Inactivated",
    i_goActivate: "Activate now",
    i_actTips: "Please download the App first",
    i_getCoins: "Get coins",
    i_howToGetCoins: "How to get coins:",
    i_chest: "Chest",
    i_luckyDraw: "Lucky draw",
    i_level: "Level",
    i_coinsForCash: "Coins for cash",
    i_shop: "Shop",
    i_reward: "Reward",
    i_img: "en"
  },
  convert: {
    i_convert: "Withdraw",
    i_convertLog: "Withdraw Record",
    i_convertT: "Convert your coins to cash",
    i_choose: "Please choose a redemption method",
    i_input: "Input",
    i_account: "Receiving account",
    i_editAccount: "Edit account",
    i_checkN: "No responsibility for wrong numbers",
    i_receivingName: "Receiving name",
    i_enterReceivingName: "Enter receiving name",
    i_select: "Select currency",
    i_currency: "Currency",
    i_pleaseSelect: "Please select",
    i_bindAccount: "Bind the Receiving account",
    i_enterReceivingAccount: "Enter receiving account",
    i_redeemNow: "Redeem now",
    i_bindN: "After binding, it cannot be changed",
    i_bindNow: "Bind now",
    i_following: "The following",
    i_bindT:
      "The account is about to be bound to your account. It cannot be changed after binding. Are you sure?",
    i_bind: "Bind",
    i_changeNow: "Change now",
    i_changeT:
      "Modifications need to be reviewed by the administrator, and cannot be exchanged during the review period. Do you want to confirm the change?",
    i_newReceivingAccount: "New receiving account",
    i_modifyReceivingAccount: "Modify the receiving account",
    i_newReceivingName: "New receiving name",
    i_confirm: "Confirm",
    i_ok: "Ok",
    i_convertMsg: "Redemption has been submitted and is under review",
    i_info_ing: "Information under review",
    i_infoN: "Information review failed",
    i_tips: "Tips",
    i_activateMsg: "Please activate Premium Features",
    i_accountN: "Account cannot be empty",
    i_nameN: "Name cannot be empty",
    i_reviewSucceeded: "Review succeeded",
    i_underReview: "Under review",
    i_auditFailure: "Audit failure",
    i_invalid: "Invalid",
    i_remark: "Remark"
  },
  inviteFriend: {
    i_inviteFriend: "Invite friends",
    i_inviteT: "Reward for each friend you invite",
    i_commissionT: "Friends' commission",
    i_total: "Total",
    i_friends: "Friends",
    i_text1: "Friends register and enter the invitation code",
    i_text2: "Activate Premium Features",
    i_text3: "become your friend",
    i_code: "Invitation code",
    i_inviteNow: "Invite now",
    i_enterCode: "Enter the code",
    i_friendCode: "Fill in the friend invite code",
    i_code1: "Only 1 invitation code can be filled in",
    i_claimNow: "Claim it now",
    i_inviteLog: "Invitation record",
    i_notActivated: "Premium features are not activated",
    i_filledIn: "Filled in",
    i_noLog: "No content yet"
  },
  invite: {
    inviteImg: "en"
  },
  task: {
    i_task: "Task Center",
    i_taskMsg: "Complete the task and receive big coins",
    i_dailyTasks: "Daily tasks",
    i_achievement: "Achievement",
    i_getNow: "Get now",
    i_goNow: "Go now",
    i_goAppGet: "Please go to the App to claim",
    i_taskT: "Task"
  },
  leaderboard: {
    i_totalCoins: "Total Coins",
    i_friends: "Friends"
  },
  inviteEvent: {
    i_received: "Received",
    i_inviteNow: "Invite now",
    i_Claim: "Claim",
    i_invite: "Invite",
    i_friends: "friends"
  },
  language: {
    zh: "中文",
    en: "English"
  }
};
export default en;
